import { LocalStorageService, LocalStorageServiceKeys } from "./LocalStorageService"
import { SessionStorageService, SessionStorageServiceKeys } from "./SessionStorageService"
import type { IStorage } from "./types"

type EStorageServiceItemKey =
    | ReturnType<(typeof LocalStorageServiceKeys)[keyof typeof LocalStorageServiceKeys]>
    | ReturnType<(typeof SessionStorageServiceKeys)[keyof typeof SessionStorageServiceKeys]>

enum EStorageServiceType {
    Local,
    Session
}

const storageMap: { [K in EStorageServiceType]: IStorage } = {
    [EStorageServiceType.Local]: LocalStorageService,
    [EStorageServiceType.Session]: SessionStorageService
}

class StorageService {
    private storage: IStorage

    constructor(storageType: EStorageServiceType = EStorageServiceType.Local) {
        this.storage = storageMap[storageType]
    }

    public setItem<T>(key: EStorageServiceItemKey, value: T): void {
        this.storage.setItem(key, value)
    }

    public getItem<T>(key: EStorageServiceItemKey): T {
        return this.storage.getItem(key)
    }

    public removeItem(key: EStorageServiceItemKey): void {
        this.storage.removeItem(key)
    }

    public clearStorage({ keysToKeep }: { keysToKeep: EStorageServiceItemKey[] } = { keysToKeep: [] }): void {
        this.storage.clear({ keysToKeep })
    }
}

export { StorageService, EStorageServiceType }

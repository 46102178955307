import { isEmpty } from "$/utils/gates"

import { storageUtils } from "./utils"

const LocalStorageServiceKeys: {
    TokenAccess: () => string
    TokenRefresh: () => string
    SocialComplete: () => string
    ArchiveModalVisible: () => string
    SubUserList: () => string
    MainUserLoggedOut: () => string
    CoachingMomentSeedQuestion: (idx: string) => string
    SurveysPageRedirected: (userId: number) => string
    FirstVisitModalShown: () => string
    LastViewedModule: () => string
} = {
    TokenAccess: (): string => "tokenAccess",
    TokenRefresh: (): string => "tokenRefresh",
    SocialComplete: (): string => "socialComplete",
    ArchiveModalVisible: (): string => "show-archive-modal",
    SubUserList: (): string => "subUserList",
    MainUserLoggedOut: (): string => "mainUserLoggedOut",
    CoachingMomentSeedQuestion: (idx: string): string => `cmcsq_${idx}`,
    SurveysPageRedirected: (id: number): string => `sp_redirect-${id}`,
    FirstVisitModalShown: (): string => "firstVisitModalShown",
    LastViewedModule: (): string => "lastViewedModule"
}

class LocalStorageService extends Storage {
    static setItem<T>(key: ReturnType<() => keyof typeof LocalStorageServiceKeys>, value: T): void {
        localStorage.setItem(key, storageUtils.setStorageItemValue<T>(value))
    }

    static getItem<T>(key: ReturnType<() => keyof typeof LocalStorageServiceKeys>): T | null {
        return storageUtils.getStorageItemValue<T>(localStorage.getItem(key))
    }

    static removeItem(key: ReturnType<() => keyof typeof LocalStorageServiceKeys>): void {
        localStorage.removeItem(key)
    }

    static clear({ keysToKeep }: { keysToKeep: (typeof LocalStorageServiceKeys)[] } = { keysToKeep: [] }): void {
        if (isEmpty(keysToKeep)) {
            localStorage.clear()
            return void 0
        }

        const keys: string[] = []
        for (let i: number = 0; i < localStorage.length; i++) {
            keys.push(localStorage.key(i))
        }

        keys.forEach((k: string): void => (keysToKeep.includes(k as never) ? void 0 : localStorage.removeItem(k)))
    }
}

export { LocalStorageService, LocalStorageServiceKeys }
